import React, { useEffect, useState } from 'react';
import './Contact.css';
import { NavLink } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import phoneImg from '../images/PhoneImg.svg';
import mailImg from '../images/MailImg.svg';
import locationImg from '../images/LocationImg.svg';
import igIcon from '../images/IgIcon.svg';
import discordIcon from '../images/DiscordIcon.svg';
import Footer from '../Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { addContact } from '../Redux/Slice/ContactSlice';
import Swal from 'sweetalert2';

export default function Contact() {
  const dispatch = useDispatch();
  const contactState = useSelector((state) => state.contact);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  // Show success or error message based on response from backend
  useEffect(() => {
    if (contactState.addContactStatus === 201 && showModal) {
      Swal.fire({
        title: 'Thanks for contacting!',
        text: 'We will get back to you soon.',
        icon: 'success',
      });
    } else if (showModal && contactState.addContactStatus !== 201) {
      Swal.fire({
        title: 'An error occurred',
        text: 'Please try again.',
        icon: 'error',
      });
    }
  }, [contactState.addContactStatus, showModal]);

  // Initialize the form data state
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    message: '',
  });

  // Handle changes in form input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show the loader when submission starts

    // Dispatch the form data without Sno, as backend will handle Sno generation
    dispatch(addContact(formData))
      .then(() => {
        setShowModal(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          message: '',
        });
        setTimeout(() => {
          setShowModal(false);
          setIsLoading(false); // Hide the loader after submission is complete
        }, 500);
      })
      .catch((err) => {
        console.error("Error submitting contact form", err);
        setIsLoading(false); // Hide the loader on error
      });
  };

  return (
    <div className="contact">
      <div className="contactTopDiv">
        <Navbar />
        <div className="contactBottomDiv">
          <div className="contactContainer">
            <div className="contactLeftContainer">
              <div className="contactLeftHeaderDiv">
                <span>Contact Information</span>
                <span>Say something and we will get back to you!</span>
              </div>

              <div className="contactLeftMiddleDiv">
                <div className="contactLeftContent">
                  <img src={phoneImg} alt="phoneImg" />
                  <span>+91 7012351551</span>
                </div>

                <div className="contactLeftContent">
                  <img src={mailImg} alt="phoneImg" />
                  <span>purakkat.rainbowgardens@gmail.com</span>
                </div>

                <div className="contactLeftContent">
                  <img src={locationImg} alt="phoneImg" />
                  <span> Kumbalam South Junction,Ernakulam, Kerala, 682506</span>
                </div>
              </div>

              <div className="contactLeftIconDiv">
              <a
                href="https://www.instagram.com/gardenshop_rainbow?igsh=MWFiNHB6bHJ5ankycg== "
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={igIcon} alt="igIcon" />
                </a>
              </div>
            </div>

            <div className="contactRightContainer">
              <form className="contactForm" onSubmit={handleFormSubmit}>
                <div className="contactFormDiv">
                  <div className="contactFormContent">
                    <span>First Name</span>
                    <input
                      type="text"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="contactFormContent">
                    <span>Last Name</span>
                    <input
                      type="text"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="contactFormDiv">
                  <div className="contactFormContent">
                    <span>Email Address</span>
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="contactFormContent">
                    <span>Mobile Number</span>
                    <input
                      type="text"
                      placeholder="Enter your mobile number"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="contactMessageDiv">
                  <span>Message to us!</span>
                  <textarea
                    type="text"
                    placeholder="Enter your message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>

                <div className="contactButtonContainer">
                  <button
                    type="submit"
                    className="contactButton"
                    disabled={isLoading} // Disable button while loading
                  >
                    {isLoading ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="contactFooterContainer">
        <Footer />
      </div>
    </div>
  );
}

import Footer from '../../../Footer/Footer';
import AdminHeader from '../../adminHeader/adminHeader';
import imagePreview from '../../../images/image-uploading-preview.svg';
import './AddOffer.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function AddOffer() {

  const navigate=useNavigate();

  const [offerData,setOfferData]=useState({
    offerName:'',
    offerLimit:'',
    offerImage:null
  });

  const [showSuccessMessage,setShowSuccessMessage]=useState(false);
  
  const handleChange=(e)=>{
    const {name,value}=e.target;
    setOfferData((prevData)=>({
      ...prevData,
      [name]:value
    }))
  }

  const handleFileUpload=(e)=>{
    setOfferData((prevData)=>({
      ...prevData,
      offerImage:e.target.files[0]
    }))
  }

  const handleFormSubmit=async(e)=>{
    e.preventDefault();
    try{
      const response= await axios.post(`${process.env.REACT_APP_BASE_URL}/createoffer`,offerData,{
        headers:{
          "Content-Type":"multipart/form-data"
        }
      });
   
      if(response.status === 201){
        setShowSuccessMessage(true);
    }
    setTimeout(()=>{
       setShowSuccessMessage(false);
       setOfferData({
        offerDescription:'',
        offerLimit:''
      })
       navigate('/admindashboard')
    },1500)
    }catch(error){
      console.log(error);
    }
  }


  return (
    <div className='admin-product-adding-page'>
    <div className="product-adding-header">
      <AdminHeader/>
    </div>
    <form className="main-content-product-adding" onSubmit={handleFormSubmit}>
    <div className="input-fields">
    <span className='main-content-title'>Add Offer</span>
    <label htmlFor="plantName">Enter offer description<span style={{ color: "green" }}>*</span> </label>
          <input required type="text" placeholder='Enter the offer description' className='product-adding-input-box' name='offerName' value={offerData.offerName} onChange={handleChange} />
          <label htmlFor="plantPrice">Enter offer limit<span style={{ color: "green" }}>*</span></label>
          <input required type="text" placeholder='Enter the offer limit' name='offerLimit' className='product-adding-input-box' value={offerData.offerLimit} onChange={handleChange}/>
    </div>
    <span className='upload-pictures-text'>Upload Image</span>
        <div className="image-upload-section">
          <div className="file-access-parent-div">
            <label className="file-access-div">
              <input
                required
                className="file-selector-input"
                type="file"
                name="offerImage"
                onChange={handleFileUpload}

              />
              <img src={imagePreview} alt="" />
            </label>
        </div>
        </div>

        <div className="form-submit-button">
          <button type="submit" className="offer-adding-button">
            Add Offer
          </button>
        </div>

        {showSuccessMessage ? (<div className='message-div'>
            <span className=''>Offer added successfully...</span>
        </div>):''}
    </form>
    <Footer />
  </div>
  )
}

export default AddOffer
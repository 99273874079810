import React, { useState } from 'react'
import AdminHeader from '../../adminHeader/adminHeader'
import Footer from '../../../Footer/Footer'
import axios from 'axios'
import './AddUser.css'
import { useNavigate } from 'react-router-dom'

function AddUser() {
    
    const navigate=useNavigate();
    
    const [userData,setUserData]=useState({
        email:'',
        password:''
    });
    const [showSuccessMessage,setShowSuccessMessage]=useState(false);

    const handleChange=(e)=>{
        const {name,value}=e.target;
        setUserData((prevState)=>({
            ...prevState,
            [name]:value
        }))
    }

    const handleFormSubmit=async(e)=>{
        e.preventDefault();
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/signup`,userData);
        console.log(response);
        if(response.status === 201){
            setShowSuccessMessage(true);
        }
        setTimeout(()=>{
           setShowSuccessMessage(false);
           setUserData({
            email:'',
            password:''
           })
           navigate('/admindashboard')
        },1500)
    }

  return (
    <div>
        <div className='admin-product-adding-page'>
    <div className="product-adding-header">
      <AdminHeader/>
    </div>
    <form className="main-content-product-adding" onSubmit={handleFormSubmit}>
    <div className="input-fields">
    <span className='main-content-title'>Add User</span>
    <label htmlFor="plantName">Enter email<span style={{ color: "green" }}>*</span> </label>
          <input required type="email" placeholder='Enter user email' className='product-adding-input-box' name='email' value={userData.email} onChange={handleChange} />
          <label htmlFor="plantPrice">Enter password<span style={{ color: "green" }}>*</span></label>
          <input required type="password" placeholder='Enter user password' name='password' className='product-adding-input-box' value={userData.password} onChange={handleChange}/>
    </div>

        <div className="form-submit-button">
          <button type="submit" className="offer-adding-button">
            Add user
          </button>
        </div>


       {showSuccessMessage ? (<div className='message-div'>
            <span className=''>User added successfully...</span>
        </div>):''}
        
    </form>
    <Footer />
  </div>
    </div>
  )
}

export default AddUser
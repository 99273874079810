import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous action to add a contact
export const addContact = createAsyncThunk(
  'contact/addContact',
  async (data, { rejectWithValue }) => {
    try {
      console.log('Sending data to backend:', data); // Log the data being sent to the backend
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/createcontact`, data);
      console.log('Response from backend:', response.data); // Log the successful response from the backend
      return response.data; // Return only serializable data from the response
    } catch (error) {
      console.log('Error sending data to backend:', error); // Log the error if something fails
      return rejectWithValue({
        message: error.response ? error.response.data.error || 'An unknown error occurred' : 'Network error',
        status: error.response ? error.response.status : 500, // Get the status code if available
        errorData: error.response ? error.response.data : null,
      });
    }
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    isLoading: false,
    addContactStatus: null,
    error: null, // Track error state separately
  },
  extraReducers: (builder) => {
    builder
      .addCase(addContact.pending, (state) => {
        console.log('addContact.pending triggered'); // Log when the pending state is triggered
        state.isLoading = true;
        state.error = null; // Clear previous errors when starting a new request
      })
      .addCase(addContact.fulfilled, (state, action) => {
        console.log('addContact.fulfilled triggered'); // Log when the request is successful
        console.log('Action payload (successful response):', action.payload); // Log the successful response
        state.isLoading = false;
        state.addContactStatus = action.payload.status || 201; // Set to 201 if status is missing
        state.error = null;
      })
      .addCase(addContact.rejected, (state, action) => {
        console.log('addContact.rejected triggered'); // Log when the request is rejected (error case)
        console.log('Error action payload:', action.payload); // Log the error payload
        state.isLoading = false;
        state.addContactStatus = action.payload.status; // Store status code (e.g., 400, 500)
        state.error = action.payload; // Store the error details (status, message, etc.)
      });
  }
});

export default contactSlice.reducer;
